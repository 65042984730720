import store from '@/store';
import nProgress from 'nprogress';
import { page } from 'vue-analytics';

const checkForUser = async (next: any) => {
  await store.dispatch('checkForUser');
  next();
};

export const _ROUTES = {
  ARTICLE: 'article',
  ARTIST: 'artist',
  BROWSE: 'browse',
  CART: 'cart',
  CHECKOUT: 'checkout',
  HELP: 'help',
  HOME: 'home',
  LIBRARY: 'library',
  LOGIN: 'login',
  MAINTENANCE: 'maintenance',
  PACK: 'pack',
  SETTINGS: 'settings',
  TIERS: 'tiers',
  VIDEO: 'video',
};

export const routes = [
  {
    path: '/',
    name: _ROUTES.HOME,
    props: true,
    component: () => import('@/views/Home.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        const collections = await store.dispatch('getHomeData');
        to.params.collections = collections;
        page('/');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/login',
    name: _ROUTES.LOGIN,
    props: true,
    component: () => import('@/views/Landing.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        const state = store.state as any;
        page('/login');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
    meta: {
      title: 'Drumnow - Login',
    },
  },
  {
    path: '/help',
    name: _ROUTES.HELP,
    props: true,
    component: () => import('@/views/Help.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/help');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/article/:slug',
    name: _ROUTES.ARTICLE,
    props: true,
    component: () => import('@/views/Article.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        await store.dispatch('getArticle', { slug: to.params.slug });
        // get the article
        page(`/article/${to.params.slug}`);

        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/tiers',
    name: _ROUTES.TIERS,
    props: true,
    component: () => import('@/views/Tiers.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/tiers');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/checkout',
    name: _ROUTES.CHECKOUT,
    props: true,
    component: () => import('@/views/Checkout.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/checkout');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/browse',
    name: _ROUTES.BROWSE,
    component: () => import('@/views/Browse.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        await store.dispatch('browse/getBrowseData');
        page('/browse');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/library',
    name: _ROUTES.LIBRARY,
    component: () => import('@/views/Library.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/library');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pack/:id',
    name: _ROUTES.PACK,
    props: true,
    component: () => import('@/views/Pack.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        await store.dispatch('getPackData', { id: to.params.id, auth: false });
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
  },
  {
    path: '/settings',
    name: _ROUTES.SETTINGS,
    props: true,
    component: () => import('@/views/Settings.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/settings');
        // const pack = await store.dispatch('getPackData', to.params.id);
        // to.params.pack = pack;
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cart',
    name: _ROUTES.CART,
    props: true,
    component: () => import('@/views/Cart.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        page('/cart');
        next();
      } catch (error) {
        return handleError(error, next);
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/video/:id',
    name: _ROUTES.VIDEO,
    props: true,
    component: () => import('@/views/Video.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      // load video data
      const video = await store.dispatch('getVideoData', to.params.id);
      page(`/video/${to.params.id}`);

      to.params.video = video;
      next();
    },
  },
  {
    path: '/artist/:id',
    name: _ROUTES.ARTIST,
    props: true,
    component: () => import('@/views/Artist.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      // load artist data
    },
  },
  {
    path: '/subscribe-success',
    name: 'subscribe-success',
    props: true,
    component: () => import('@/views/SubscribeSuccess.vue'),
    beforeEnter: async (to: any, from: any, next: any) => {
      page('/subscribe-success');
      next();
    },
  },
];

const handleError = async (error: Error, next: any) => {
  await store.dispatch('handleError', error);
  return next(false);
};
