





























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SettingsControls extends Vue {
  get disabled() {
    return !this.$store.state.user.authenticated;
  }

  get state() {
    return this.$store.state.player;
  }

  get currentTempo() {
    return this.state.tempo;
  }

  get tempos() {
    return this.state.tempos;
  }

  get currentStyle() {
    return this.state.style;
  }

  get styles() {
    return this.state.styles;
  }

  get wet() {
    return this.state.wet;
  }

  updateSettings(settings: any) {
    this.$store.dispatch('updatePlayerSettings', settings);
    this.$emit('update');
  }

  handleTempoChange(tempo: any) {
    this.updateSettings({
      tempo,
      style: this.currentStyle,
      wet: this.wet,
    });
  }

  handleStyleChange(style: any) {
    this.updateSettings({
      tempo: this.currentTempo,
      style,
      wet: this.wet,
    });
  }

  handleProductionChange(wet: any) {
    this.updateSettings({
      tempo: this.currentTempo,
      style: this.currentStyle,
      wet,
    });
  }
}
