import axios, { AxiosInstance } from 'axios';

import { NodeService } from '@/services/node.service';

const URLS = {
  local: 'http://localhost:3000',
  dev: 'https://dn-swarm-dev.herokuapp.com',
  staging: '',
  prod: 'https://swarm.drumnow.app',
};

const TIMEOUT = 5000;

export class SwarmServiceClass {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: URLS.prod,
      timeout: TIMEOUT,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  addAuthHeader(token: string) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  static handleError(error: Error): void {
    // console.error('SwarmService Error:', error);
  }
}

export const SwarmService = new SwarmServiceClass();
