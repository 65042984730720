import { ICartData, ICartItem } from '@/components/cart/types';
import { Module } from 'vuex';

export interface CartState {
  items: ICartItem[];
  data: ICartData;
}

export const CartModule: Module<CartState, any> = {
  namespaced: false,
  state: {
    items: [],
    data: {
      subtotal: 0,
      tax: 0,
      total: 0,
    },
  },
  getters: {
    packInCart: state => (id: string) => {
      return !!state.items.find(item => item._id === id);
    },
    formattedTotal: state => {
      return state.data.total.toFixed(2);
    },
  },
  mutations: {
    addItemToCart(state, item) {
      state.items.push(item);
    },
    removeItemFromCart(state, item) {
      const index = state.items.findIndex(i => i._id === item._id);
      state.items.splice(index, 1);
    },
    updateCartData(state) {
      // calculate just the total for now
      let total = 0;

      state.items.forEach(item => {
        total += item.price;
      });

      state.data.total = total;
    },
  },
  actions: {
    addToCart({ commit }, item) {
      commit('addItemToCart', item);
      commit('updateCartData');
    },
    removeFromCart({ commit }, item) {
      commit('removeItemFromCart', item);
      commit('updateCartData');
    },
  },
};
