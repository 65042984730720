import { render, staticRenderFns } from "./VolumeControls.vue?vue&type=template&id=5e0914cf&scoped=true&"
import script from "./VolumeControls.vue?vue&type=script&lang=ts&"
export * from "./VolumeControls.vue?vue&type=script&lang=ts&"
import style0 from "./VolumeControls.vue?vue&type=style&index=0&id=5e0914cf&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e0914cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VIcon,VList,VMenu,VSlider})
