import Tone, { Transport, Loop, Time, TimeClass, now } from 'tone';

import { StoreOptions } from 'vuex';

export interface PlayerState {
  item: any;
  items: any;
  tempo: any;
  tempos: any;
  style: any;
  styles: any;
  loading: boolean;
  wet: boolean;
  thumbnailUrl: string;
  expanded: boolean;
  title: string;
  playing: boolean;
  looping: boolean;
  transport: Tone.PlaybackState;
  subtitle: string;
}

export const PlayerModule: StoreOptions<any> = {
  state: {
    item: {},
    items: [],
    tempo: {},
    tempos: [],
    style: {},
    styles: [],
    wet: false,
    loading: false,
    looping: false,
    thumbnailUrl: '',
    expanded: false,
    transport: 'stopped',
    title: '',
    subtitle: '',
  },
  mutations: {
    setCurrentPlayerItem(state, payload) {
      state.item = payload;
    },
    setPlayerItems(state, payload) {
      state.items = payload;
    },
    setCurrentTempo(state, payload) {
      state.tempo = payload;
    },
    setTempos(state, payload) {
      state.tempos = payload;
    },
    setStyles(state, payload) {
      state.styles = payload;
    },
    setCurrentStyle(state, payload) {
      state.style = payload;
    },
    setProduction(state, payload) {
      state.wet = payload;
    },
    setThumbnail(state, payload) {
      state.thumbnailUrl = payload;
    },
    toggleLoading(state, payload) {
      state.loading = !state.loading;
    },
    'player:toggleExpanded': state => {
      state.expanded = !state.expanded;
    },
    'player:setMeta': (state, payload) => {
      const { title, subtitle } = payload;
      state.title = title;
      state.subtitle = subtitle;
    },
    'player:setPlayerState': (state, payload) => {
      const { newVal, oldVal } = payload;

      state.transport = newVal;
    },
    'player:toggleLooping': state => {
      state.looping = !state.looping;
    },
    clearPlayerState(state) {
      state.item = {};
      state.items = [];
      state.tempo = {};
      state.tempos = [];
      state.style = {};
      state.styles = [];
      state.wet = false;
      state.loading = false;
      state.looping = false;
      state.thumbnailUrl = '';
      state.expanded = false;
      state.transport = 'stopped';
      state.title = '';
      state.subtitle = '';
    },
  },
  actions: {
    loadPlayerSample({ state, commit }, payload) {
      const { item } = payload;
    },
    loadPlayerItems({ state, commit }, payload) {
      const { items } = payload;
      const initial = items[0];

      commit('setPlayerItems', items);

      commit('setCurrentPlayerItem', initial);

      commit('setTempos', initial.tempos);
      commit('setStyles', initial.styles);

      commit('setProduction', true);

      commit('setCurrentTempo', initial.tempos[0]);
      commit('setCurrentStyle', initial.styles[0]);
    },
    updatePlayerSettings({ state, commit }, payload) {
      const { tempo, style, wet } = payload;

      commit('setProduction', wet);
      commit('setCurrentTempo', tempo);
      commit('setCurrentStyle', style);
    },
    updateThumbnail({ state, commit }, payload) {
      const { thumbnailUrl } = payload;
      commit('setThumbnail', thumbnailUrl);
    },
    updatePlayerItem({ state, commit }, payload) {
      const { item } = payload;
      commit('setCurrentPlayerItem', item);
    },
    clearPlayerState({ commit }) {
      commit('clearPlayerState');
    },
  },
  modules: {},
};
