



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Logo from '@/components/Logo.vue';

import { _ROUTES } from '@/router/routes';

import { mapState } from 'vuex';

@Component({
  components: {
    Logo,
  },
  computed: {
    ...mapState(['user']),
  },
})
export default class Sidebar extends Vue {
  // @Prop({ default: false }) value!: Boolean;

  user: any;
  value = false;
  routes = _ROUTES;

  get navigationDrawerAttrs() {
    return {
      class: `navigation-drawer`,
      app: true,
    };
  }

  get menuItems() {
    let authTabs = [];

    if (this.user.credential) {
      authTabs.push(
        ...[
          {
            name: 'Library',
            path: '/library',
            icon: 'library',
          },
          {
            name: 'Cart',
            path: '/cart',
            icon: 'cart',
          },
        ],
      );
    }

    return [
      {
        name: 'Home',
        path: '/',
        icon: 'home',
      },
      {
        name: 'Browse',
        path: '/browse',
        icon: 'search',
      },
      {
        name: 'Support',
        path: '/help',
        icon: 'help',
      },
      ...authTabs,
    ];
  }

  get activeItem() {
    return this.menuItems.find(
      (item: any) => item.name.toLowerCase() === this.$route.name,
    );
  }

  openStore() {
    window.open('https://store.drumnow.io', '_blank');
  }

  handleDrawerInput() {
    // if drawer open, emit false
    this.$emit('input', this.value);
    // this.$emit('toggle');
  }

  logout() {
    this.$store.dispatch('logout').then(() => {
      this.$router.replace('/login');
    });
  }
}
