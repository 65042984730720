










import Vue from 'vue';
import { mapState } from 'vuex';
import { Bus } from '@/services/bus.service';

export default Vue.extend({
  computed: {
    ...mapState(['snackbar']),
  },
  data: () => ({
    show: false,
  }),
  methods: {
    toggle() {
      this.show = !this.show;
    },
    close() {
      this.show = false;
    },
  },
  mounted() {
    Bus.$on('toggleSnackbar', this.toggle);
  },
  destroyed() {
    Bus.$off('toggleSnackbar', this.toggle);
  },
});
