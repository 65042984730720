import { SwarmService } from '@/services/swarm.service';
import { NodeService } from '@/services/node.service';
import { Bus } from '@/services/bus.service';
import { Module } from 'vuex';

export interface SnackbarState {
  message: string;
  color: 'success' | 'error';
  show: boolean;
}

export const SnackbarModule: Module<any, any> = {
  namespaced: true,
  state: {
    message: '',
    color: 'success',
  },
  mutations: {
    setMessage(state, message) {
      const st = state as SnackbarState;
      st.message = message;
    },
    setColor(state, color) {
      const st = state as SnackbarState;
      st.color = color;
    },
  },
  actions: {
    pushMessage({ commit }, { message }) {
      commit('setMessage', message);
      commit('setColor', 'primary');
      Bus.$emit('toggleSnackbar');
    },
    pushError({ commit }, { message }) {
      commit('setMessage', message);
      commit('setColor', 'error');
      Bus.$emit('toggleSnackbar');
    },
  },
  modules: {},
};
