import { NodeService } from '@/services/node.service';
import { StoreOptions } from 'vuex';

export interface ArticleState {
  current: {};
}

export const ArticleModule: StoreOptions<{}> = {
  state: {
    current: {},
  },
  mutations: {
    setCurrentArticle(state, payload) {
      const sstate = state as ArticleState;
      sstate.current = payload;
    },
  },
  actions: {
    async getArticle({ commit, state }, payload) {
      const { slug } = payload;
      const article = await NodeService.getArticle(slug);

      commit('setCurrentArticle', article.data);
    },
  },
};
