import { StoreOptions } from 'vuex';
import auth from '@/plugins/auth';

import { NodeService } from '@/services/node.service';
import { SwarmService } from '@/services/swarm.service';
import { KnoxService } from '@/services/knox.service';

// TODO: Break out into env
const PERMISSIONS = {
  ORIGIN_STREAMING_ACCESS: process.env.VUE_APP_ORIGIN_STREAMING_ACCESS,
  ORIGIN_LOOP_DOWNLOAD_ACCESS: process.env.VUE_APP_ORIGIN_LOOP_DOWNLOAD_ACCESS,
  ORIGIN_EXCLUSIVE_CONTENT: process.env.VUE_APP_ORIGIN_EXCLUSIVE_CONTENT,
};

export interface UserState {
  credential: string;
  profile: any;
  authenticated: boolean;
  library: any;
}

export const UserModule: StoreOptions<{}> = {
  state: (): UserState => ({
    credential: '',
    profile: {},
    authenticated: false,
    library: {},
  }),
  getters: {
    entitled_ExclusiveContent(state) {
      const sstate = state as UserState;
      return sstate.profile
        ? sstate.profile.tier.permissions.length > 0
        : false;
    },
    USER__LIBRARY(state) {
      let statee = state as UserState;
      return statee.profile.library;
    },
    USER__PROFILE_CREATED(state) {
      let sstate = state as UserState;
      return !!(sstate.profile && sstate.credential);
    },
    USER__EMAIL_CONFIRMED(state) {
      return auth.currentUser?.emailVerified;
    },
    USER__AUTHENTICATED(state, getters) {
      return (
        getters['USER__PROFILE_CREATED'] && getters['USER__EMAIL_CONFIRMED']
      );
    },
    // Utility Permission getters
    USER__GET_CAN_PURCHASE_PACK() {
      return true;
    },
    USER__GET_CAN_ADD_TO_LIBRARY() {
      return false;
    },
    USER__ENTITLED_EXCLUSIVE(state) {
      let sstate = state as UserState;

      if (!sstate.profile.tier) {
        return false;
      }

      const permissions = sstate.profile.tier.permissions;

      return !!permissions.find(
        (perm: any) => perm._id === PERMISSIONS.ORIGIN_EXCLUSIVE_CONTENT,
      );
    },
    USER__CAN_DOWNLOAD_LOOPS(state) {
      let sstate = state as UserState;

      if (!sstate.profile.tier) {
        return false;
      }

      const permissions = sstate.profile.tier.permissions;

      return !!permissions.find(
        (perm: any) => perm._id === PERMISSIONS.ORIGIN_LOOP_DOWNLOAD_ACCESS,
      );
    },
    USER__CAN_STREAM_LOOPS(state) {
      let sstate = state as UserState;

      if (!sstate.profile.tier) {
        return false;
      }

      const permissions = sstate.profile.tier.permissions;

      return !!permissions.find(
        (perm: any) => perm._id === PERMISSIONS.ORIGIN_STREAMING_ACCESS,
      );
    },
    USER__EMAIL(state) {
      const sstate = state as UserState;
      return sstate.profile.email || '';
    },
  },
  mutations: {
    setProfile(state, payload: any) {
      const sstate = state as UserState;
      sstate.profile = payload;
    },
    setToken(state, payload: any) {
      const sstate = state as UserState;
      sstate.credential = payload;
      sstate.authenticated = !!payload;
    },
    setAuth(state, payload: UserState) {
      const sstate = state as UserState;
      const { credential, profile, authenticated } = payload;
      sstate.credential = credential;
      sstate.profile = profile;
      sstate.authenticated = authenticated;
    },
    resetUser(state) {
      const sstate = state as UserState;
      sstate.credential = '';
      sstate.profile = {};
      sstate.authenticated = false;
    },
  },
  actions: {
    async checkForUser({ dispatch, commit, state }, user = auth.currentUser) {
      if (user) {
        const { email } = user;
        const token = await user.getIdToken();

        if (!user.emailVerified) {
          // add token to services
          return;
        }

        if (token) {
          NodeService.addAuthHeader(token);
          SwarmService.addAuthHeader(token);
        }

        // get user data
        const data = await dispatch('getUserData', email);

        const library = await NodeService.getLibrary(data.data.user.library);

        data.data.user.library = library;

        commit('setProfile', data.data.user);
        commit('setToken', token);

        // dispatch('')
      }
    },
    changePassword({ dispatch }, password) {
      // call firebase auth to change pwd
      const user = auth.currentUser;
      return user
        ?.updatePassword(password)
        .then(() => {
          // message
          dispatch('snackbar/pushMessage', {
            message: `Password update successful`,
          });
        })
        .catch(error => {
          dispatch('snackbar/pushError', {
            message: `Error: ${error.message}`,
          });
        });
    },
    async addStripeToken({ state }, token) {
      // get user id
      const sstate = state as UserState;
      const id = sstate.profile._id;

      // call service
      await NodeService.addStripeTokenToUser(token, id);
    },
    async addTier({ state }, tierId) {
      // get user id
      const sstate = state as UserState;
      const id = sstate.profile._id;

      // call service
      await NodeService.addTierToUser(tierId, id);
    },
    async getUserData({ commit, state }, email) {
      const data = await NodeService.signIn(email);
      const user = data.data.user;

      return data;
    },
    async updateProfile({ dispatch }, payload) {
      const { id, profile, avatar } = payload;

      if (avatar) {
        // upload the avatar first
        await NodeService.updateUserAvatar(id, avatar.name, avatar);
      }

      // cleanse profile
      delete profile.avatarUrl;
      delete profile.tier;
      delete profile._id;
      delete profile.__v;
      delete profile.library;

      const response = await NodeService.updateUser(id, profile);
      const newProfile = response.data;
      await dispatch('checkForUser');
      return newProfile;
    },
    async createUser({ commit, dispatch }, { email, password }) {
      try {
        const creation = await NodeService.createBasicUser(email, password);

        // sign in user
        await dispatch('firstTimeLogin', { email, password });

        return creation;
      } catch (error) {
        return { success: false };
      }
    },
    async firstTimeLogin({ commit, dispatch }, { email, password }) {
      try {
        const userAuth = await auth.signInWithEmailAndPassword(email, password);
        if (!userAuth.user?.emailVerified) {
          userAuth.user?.sendEmailVerification({
            url: `${window.location.origin}/tiers`,
            handleCodeInApp: true,
          });
        }

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },
    async login({ commit, dispatch }, { email, password }) {
      try {
        const userAuth = await auth.signInWithEmailAndPassword(email, password);
        if (userAuth && userAuth.user) {
          const token = await userAuth.user.getIdToken();

          if (!userAuth.user.emailVerified) {
            throw new Error('Email Verification Required');
          }

          if (token) {
            NodeService.addAuthHeader(token);
            SwarmService.addAuthHeader(token);
            KnoxService.addAuthHeader(token);
          }

          const userData = await NodeService.signIn(email);
          const { user } = userData.data;

          const library = await NodeService.getLibrary(user.library);

          user.library = library;

          const payload = {
            profile: user,
            credential: token,
            authenticated: true,
          };

          localStorage.setItem('dn_origin_user', JSON.stringify(payload));
          commit('setAuth', payload);
          return { success: true };
        }

        return { success: false };
      } catch (error) {
        return { success: false };
      }
    },
    async forgotPassword({ commit }, { email }) {
      try {
        await auth.sendPasswordResetEmail(email);
      } catch (error) {
        //
      }
    },
    async logout({ commit }) {
      try {
        await auth.signOut();
        commit('resetUser');
      } catch (error) {
        //
      }
    },
  },
  modules: {},
};
