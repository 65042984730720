import { NodeService } from '@/services/node.service';
import { StoreOptions } from 'vuex';
import { IFormParams } from '@/interfaces/IFormParams';

export const FormModule: StoreOptions<{}> = {
  actions: {
    async submitForm({ commit, dispatch }, payload: IFormParams) {
      // call node service
      await NodeService.submitForm(payload);

      // handle messaging
      dispatch('snackbar/pushMessage', { message: 'Message Recieved!' });
    },
  },
};
