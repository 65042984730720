import Tone, {
  Player,
  Transport,
  Loop,
  Time,
  TimeClass,
  Volume,
  now,
} from 'tone';

import { IPlayerQueueItem, IPlayerQueueOptions } from '../interfaces';

/**
 * reusable class for managing player queue
 */
export class PlayerQueue {
  constructor(options: IPlayerQueueOptions) {
    const { items } = options;
    this._items = items;
  }

  private _items: any[];

  private _queue!: IPlayerQueueItem[];
  private _current!: IPlayerQueueItem;

  public get loaded() {
    // player is loaded if there is a queue and each has a player
    return this._queue && this._queue.length && this._queue[0].player;
  }

  public get currentItem(): IPlayerQueueItem {
    return this._current;
  }

  private _getCurrentIndex() {
    return this._queue.indexOf(this._current);
  }

  private _shiftQueue(amt: number) {
    const index = this._getCurrentIndex();
    const length = this._queue.length;

    let shifted = index + amt;

    if (shifted === length) {
      shifted = 0;
    } else if (shifted === -1) {
      shifted = length - 1;
    }

    // set current to that index
    if (this._queue[shifted]) {
      this._setCurrent(this._queue[shifted]);
    }
  }

  /**
   * Advance the queue and return the item
   */
  public next() {
    // get index of current
    this._shiftQueue(1);
    return this._current;
  }

  /**
   * Rewind the queue
   */
  public previous() {
    this._shiftQueue(-1);
    return this._current;
  }

  public set(loop: any) {
    // get id
    const { _id } = loop;

    // find index
    const queued = this._queue.find(item => item._id === _id);

    if (queued) {
      this._setCurrent(queued);
    }
  }

  /**
   * Load the queue for playing
   */
  public load(vol: Volume) {
    if (!this._items.length) {
      return;
    }

    // massage data
    this._queue = this._items.map((item: any): IPlayerQueueItem => {
      const i = {
        _id: item._id,
        loopUrl: item.variant.loopUrl,
        player: new Player().connect(vol),
        bpm: item.variant.tempo.value,
        variantId: item.variant._id,
      };

      return i;
    });

    // set first item as current
    this._setCurrent(this._queue[0]);
  }

  public destroy() {
    this._items = [];
    this._queue = [];
    this._current.player.dispose();
  }

  private _setCurrent(item: IPlayerQueueItem) {
    this._current = item;
  }
}
