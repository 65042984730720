import { render, staticRenderFns } from "./Player.vue?vue&type=template&id=15486fcb&scoped=true&"
import script from "./Player.vue?vue&type=script&lang=ts&"
export * from "./Player.vue?vue&type=script&lang=ts&"
import style0 from "./Player.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./Player.vue?vue&type=style&index=1&id=15486fcb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15486fcb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSlider,VSpacer})
