import { IUrlMap } from '@/interfaces/IUrlMap';

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
);

export const getServiceUrl = (
  env: string = process.env.NODE_ENV,
  urls: IUrlMap,
) => {
  let url = '';

  switch (env) {
    case 'development':
      url = urls.dev;
      break;
    case 'staging':
    case 'test':
      url = urls.staging;
      break;
    case 'production':
    default:
      url = urls.prod;
      break;
  }

  return url;
};
