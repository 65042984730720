

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class VolumeControls extends Vue {
  volume = -6;

  get disabled() {
    return !this.$store.state.user.authenticated;
  }
}
