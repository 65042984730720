type LogType = 'error' | 'message' | 'warning';

export interface ILogParams {
  type: LogType;
  message: string;
}

export class PlayerLogger {
  constructor(debug: boolean = false) {
    this._debug = debug;
  }

  /**
   * Logger debug flag
   */
  _debug: boolean = false;

  /**
   * Simple Message API
   * @param message
   */
  message(message: string) {
    this.log({
      type: 'message',
      message,
    });
  }

  /**
   * Complex log API
   * @param params
   */
  log(params: ILogParams) {
    const { type, message } = params;

    switch (type) {
      case 'error':
        this._error(message);
        break;
      case 'message':
        this._message(message);
        break;
      case 'warning':
        this._warning(message);
        break;
      default:
        this._message(message);
        break;
    }
  }

  private _error(message: string) {}

  private _message(message: string) {}

  private _warning(message: string) {}
}
