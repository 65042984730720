import { getServiceUrl } from '@/helpers';
import axios, { AxiosInstance, AxiosError } from 'axios';

const URLS = {
  local: 'http://localhost:3000/',
  dev: 'https://knox-dev-lakinlggia-uc.a.run.app/',
  staging: 'https://knox.drumnow.app/',
  prod: 'https://knox.drumnow.app/',
};

const TIMEOUT = 50000;

export interface CartCheckoutItem {
  item: string;
  itemId: string;
  itemType: 'pack' | 'other';
}

export class KnoxServiceClass {
  axios: AxiosInstance;
  debug = false;

  constructor(debug = false) {
    this.axios = axios.create({
      baseURL: getServiceUrl(process.env.NODE_ENV, URLS),
      timeout: TIMEOUT,
    });

    this.debug = debug;
  }

  addAuthHeader(token: string) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  /**
   * Create a new checkout session
   */
  async createSubscriptionCheckoutSession(
    tier: 'pro' | 'core',
    customer: string,
  ) {
    try {
      const successUrl = `${window.location.origin}/subscribe-success`;
      const cancelUrl = `${window.location.origin}/tiers`;

      const response = await this.axios.get('/create-checkout-session', {
        params: { tier, customer, successUrl, cancelUrl },
      });
      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  async createPortalSession(customer: string) {
    try {
      const returnUrl = `${window.location.origin}/settings`;
      const response = await this.axios.post(
        '/create-customer-portal-session',
        { customerId: customer, returnUrl },
      );

      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  async createItemCheckoutSession(
    itemId: string,
    cus: string,
    successUrl: string,
    cancelUrl: string,
    itemType: 'pack' | 'other',
    stripePriceId: string,
  ) {
    try {
      const response = await this.axios.post('/create-item-checkout-session', {
        item: stripePriceId,
        customerId: cus,
        successUrl,
        cancelUrl,
        itemType,
        itemId,
      });

      return response.data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  /**
   * creates 
   * @param items {} - items to purchase 
   * @param customer {} - customer to create payment intent for
   * @param successUrl {} - url to redirect to after successful payment
   * @param cancelUrl {} - url to redirect to after cancelled payment
   * @returns {} - payment intent
   */
  async createCartCheckoutSession(
    items: CartCheckoutItem[],
    customer: string,
    successUrl: string,
    cancelUrl: string,
  ) {
    try {
      const response = await this.axios.post('/create-item-checkout-session', {
        items,
        customerId: customer,
        successUrl,
        cancelUrl,
      });

      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  async createCartCheckoutSessionWithPaymentIntent() {
    try {
      const response = await this.axios.post('/create-cart-checkout-session-with-payment-intent');
      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }
  /**
   * Create a new payment intent for purchasing items
   */
  async createPaymentIntent(items: any[]) {
    try {
      const response = await this.axios.post('/create-payment-intent', {
        items,
      });
      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  async getItemPricing(items: any[]) {
    try {
      const response = await this.axios.post('/get-prices', { items });
      return response.data;
    } catch (error) {
      this._handleError(error);
    }
  }

  /**
   * Create new customer
   */
  createCustomer() {}

  _handleError(error: AxiosError) {
    if (this.debug) {
      /* eslint-disable */
      console.log('KnoxService:', error);
    }

    throw new Error(error.message);
  }
}

export const KnoxService = new KnoxServiceClass(true);
